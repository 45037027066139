.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.landingHeading {
  background: url(./images/soccer.jpg) no-repeat center center;
  background-size: cover;
  width: 100%
}

.ui.fullscreen.modal.active {
  left: 2.5% !important;
}