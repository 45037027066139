.highlight {
    animation: fadeoutBg 6s; /***Transition delay 3s fadeout is class***/
    -moz-animation: fadeoutBg 6s; /* Firefox */
    -webkit-animation: fadeoutBg 6s; /* Safari and Chrome */
    -o-animation: fadeoutBg 6s; /* Opera */
}

@keyframes fadeoutBg {
    from { background-color: lightgoldenrodyellow; } /** from color **/
    to { background-color: transparent; } /** to color **/
}

@-moz-keyframes fadeoutBg { /* Firefox */
    from { background-color: lightgoldenrodyellow; }
    to { background-color: transparent; }
}

@-webkit-keyframes fadeoutBg { /* Safari and Chrome */
    from { background-color: lightgoldenrodyellow; }
    to { background-color: transparent; }
}

@-o-keyframes fadeoutBg { /* Opera */
    from { background-color: lightgoldenrodyellow; }
    to { background-color: transparent; }
}

.addHeight {
    height: 24px;
}

.semanticDropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    min-width: 14em;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: .78571429em 2.1em .78571429em 1em;
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    -webkit-transition: box-shadow .1s ease,width .1s ease;
    transition: box-shadow .1s ease,width .1s ease;
    z-index: 10;
}